var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer supply-chain" },
    [
      _c(
        "search-panel",
        {
          attrs: { "show-btn": "", flex: "" },
          on: {
            getList: function ($event) {
              return _setup.getList(1)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v("商品类目")]),
              _c(_setup.ChangeCat, {
                attrs: { "parent-id": 1 },
                on: { change: _setup.handleChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("p", [_vm._v("商品名称")]),
              _c("el-input", {
                attrs: {
                  clearable: "",
                  placeholder: "请输入内容",
                  size: "mini",
                },
                on: {
                  clear: function ($event) {
                    return _setup.getList(1)
                  },
                },
                model: {
                  value: _setup.params.goodsName,
                  callback: function ($$v) {
                    _vm.$set(_setup.params, "goodsName", $$v)
                  },
                  expression: "params.goodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "button-right", size: "mini" },
              on: {
                click: function ($event) {
                  return _setup.operation("reset")
                },
              },
              slot: "button-right",
            },
            [_vm._v(" 重 置 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticClass: "tableBox",
          staticStyle: { width: "100%" },
          attrs: {
            data: _setup.tableData,
            "element-loading-text": "拼命加载中",
            "row-key": "id",
            "header-cell-style": {
              background: "#f5f7fa",
            },
            size: "medium",
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { "margin-left": "148px" },
                        attrs: {
                          data: row.tradeGoodsSkuDtoList || [],
                          size: "medium",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "商品规格" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "wd-flex wd-flex-align-center",
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "50px",
                                            height: "50px",
                                          },
                                          attrs: {
                                            src: scope.row.imageIds,
                                            "preview-src-list": [
                                              scope.row.imageIds,
                                            ],
                                            fit: "cover",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "margin-l5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row.specValue) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "店铺销售价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(+scope.row.sellPrice) + " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { label: "供货价" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " + _vm._s(+scope.row.supplyPrice) + " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsName", label: "商品名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "backendCategoryPath", label: "商品类目" },
          }),
        ],
        1
      ),
      _setup.tableData && _setup.tableData.length > 0
        ? _c("el-pagination", {
            attrs: {
              "current-page": _setup.params.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _setup.params.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _setup.params.count,
            },
            on: {
              "size-change": _setup.handleSizeChange,
              "current-change": _setup.handleCurrentChange,
            },
          })
        : _vm._e(),
      _c(_setup.AddGoodsDialog, {
        attrs: { show: _setup.showAddDialog },
        on: {
          clear: function ($event) {
            _setup.showAddDialog = false
          },
          success: function ($event) {
            return _setup.getList(1)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }